<template>
  <div>
    <div v-if="loading">
      <div class="container mx-auto">
        <div class="p-4 text-center" v-show="!queued">
          <p>Page is loading...</p>
        </div>
        <div class="p-4 text-center" v-show="queued">
          <div v-html="queueText"></div>
          <div class="mt-4">
            <p>
              Your estimated position is: {{ position }}.
              <br/>
              <br/>
              Wait time is around {{ position }} minute(-s)
            </p>
          </div>
          <div class="w-full text-center">
            <img :src="queueImage" class="mx-auto"/>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loading">
      <div class="w-full">
        <div class="hidden md:inline-block md:w-1/4 align-top p-4">
          <steps-list></steps-list>
        </div>
        <div class="w-full md:w-2/4 inline-block align-top p-4">

          <div v-show="eventIsOpenForRegistration">
            <form-list v-if="getCurrentState === 'form-list'"></form-list>

            <div v-if="getCurrentState === 'forms'">
              <form-display
                  v-for="(form, index) in getStateFormsList"
                  v-bind:key="'form-display' + form.id + index"
                  v-show="getCurrentForm === form.id"
                  :form="form"
                  :index="index"
              ></form-display>
            </div>

            <payment-page v-if="getCurrentState === 'payment'"></payment-page>
          </div>
          <div v-show="!eventIsOpenForRegistration">
            <div v-if="!this.eventClosed">
              {{ $t('eventHasAlreadyPassed') }}
            </div>
            <div v-else-if="!this.registrationClosed">
              {{ $t('eventRegistrationIsClosed') }}
            </div>
          </div>
        </div>
        <div class="hidden md:inline-block md:w-1/4  align-top p-4">
          <event-details></event-details>
          <cart-details></cart-details>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {mapActions, mapGetters} from 'vuex'

import FormList from '@/components/event/eventRegistration/Steps/FormList'
import EventDetails from '@/components/event/eventRegistration/EventDetails'
import CartDetails from '@/components/event/cart/CartDetails'
import FormDisplay from '@/components/event/eventRegistration/Details/FormDisplay'
import StepsList from '@/components/event/eventRegistration/StepsList'
import PaymentPage from '@/components/event/eventRegistration/Details/PaymentPage'
import DiscountCode from '@/components/event/cart/DiscountCode'

export default {
  name: 'EventRegistration',
  components: {
    PaymentPage,
    FormDisplay,
    StepsList,
    CartDetails,
    FormList,
    EventDetails
  },
  mounted() {
    // if back button is pressed
    history.pushState(null, null, '#beginning')
    window.addEventListener('popstate', (event) => {
      history.pushState(null, null, '#beginning')
      this.$swal({
        title: this.$t('browserBackTitle'),
        text: this.$t('browserBackText'),
        icon: 'info'
      })
    })
  },
  created() {
    let eventID = this.$route.params.id
    if (this.loading) {
      let token = window.localStorage.getItem(`event_${eventID}_queue_token`)
      if (!token) {
        axios.get('queue/' + eventID)
            .then((resp) => {
              if (resp.data.enabled) {
                this.queued = true
                this.queueText = resp.data.text
                this.queueImage = resp.data.image
                this.position = resp.data.position
                window.localStorage.setItem(`event_${eventID}_queue_token`, resp.data.token)
                window.localStorage.setItem(`event_${eventID}_queue_text`, resp.data.text)
                window.localStorage.setItem(`event_${eventID}_queue_image`, resp.data.image)
              } else {
                this.loading = false
                window.localStorage.removeItem(`event_${eventID}_queue_token`)
                window.localStorage.removeItem(`event_${eventID}_queue_text`)
                window.localStorage.removeItem(`event_${eventID}_queue_image`)
              }
            })
      } else {
        if (window.localStorage.getItem(`event_${eventID}_queue_passed`)) {
          this.queued = false
          this.loading = false
        } else {
          this.queued = true
          this.queueText = window.localStorage.getItem(`event_${eventID}_queue_text`)
          this.queueImage = window.localStorage.getItem(`event_${eventID}_queue_image`)
        }
      }
    }

    this.$root.$emit('LocaleChanger::beforeChange', this.confirmLanguageChange)
    this.$root.$on('LocaleChanger::changed', locale => window.location.reload())
  },
  data: function () {
    return {
      loading: true,
      queued: false,
      queueText: null,
      queueImage: null,
      position: 1
    }
  },
  computed: {
    ...mapGetters('event', ['getEventDetails']),
    ...mapGetters(
        'eventRegistration',
        [
          'getCurrentState',
          'getCurrentForm',
          'getStateFormsList',
          'getCurrentSubStep',
          'getInputData',
          'bypassClosedEvent',
          'bypassClosedRegistration'
        ]
    ),
    ...mapGetters('cart', ['cartHasItems']),
    eventIsOpenForRegistration: function () {
      return this.registrationClosed && this.eventClosed
    },
    registrationClosed: function () {
      if (this.bypassClosedRegistration) {
        return true
      }
      return this.getEventDetails.registration_open
    },
    eventClosed: function () {
      if (this.bypassClosedEvent) {
        return true
      }
      return this.getEventDetails.event_open
    }
  },
  watch: {
    loading: function () {
      let app = this
      if (!app.loading) {
        let eventID = this.$route.params.id
        axios.get('event/' + eventID)
            .then(function (resp) {
              app.setEventDetails(resp.data.data)
              app.setGroups(resp.data.data.groups)
              app.setForms(resp.data.data.parent_forms)
            })
        let type = this.$route.params.type
        let token = this.$route.params.token
        if (type && token) {
          axios.post('exceptions/' + eventID, {
            type, token
          }).then((resp) => {
            app.saveExceptionResponse(resp.data.data)
          }).catch((resp) => {
            if (resp.response && resp.response.data && resp.response.data.error) {
              app.$swal.fire({
                title: resp.response.data.error,
                text: resp.response.data.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: this.$t('ok'),
                cancelButtonText: this.$t('cancel')
              })
            }

          })
        }
      }
    },
    queued: function () {
      let app = this
      if (app.queued) {
        app.checkQueueStatus()
      }
    }
  },
  methods: {
    ...mapActions('event', ['setEventDetails', 'setGroups', 'setForms']),
    ...mapActions('eventRegistration', ['setStep', 'saveInputData', 'saveExceptionResponse']),
    nextPartial(step) {
      this.setStep({
        step: step,
        form: this.getCurrentForm
      })
    },
    confirmLanguageChange() {
      if (!this.cartHasItems) {
        return true
      }

      return new Promise((resolve, reject) => {
        this.$swal.fire({
          title: this.$t('localChangeConfirmTitle'),
          text: this.$t('localeChangeFormWillClear'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('ok'),
          cancelButtonText: this.$t('cancel')
        }).then((result) => {
          if (result.value) {
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    checkQueueStatus() {
      let app = this
      let eventID = this.$route.params.id
      if (app.queued) {
        setTimeout(function () {
          axios.get('queue/' + eventID + '/check/' + window.localStorage.getItem(`event_${eventID}_queue_token`))
              .then(function (resp) {
                if (resp.data.passed) {
                  app.queued = false
                  app.loading = false
                  window.localStorage.setItem(`event_${eventID}_queue_passed`, true)
                } else {
                  app.checkQueueStatus()
                }
              })
        }, 1000 * 60)
      }
    }
  }
}
</script>

<style scoped>

</style>